import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/icon/iconfont.css";
import App from './App.vue'
import router from './router'
import store from './store'
import userMenu from './components/user-menu.vue'
import Storage from '@/utils/storage.js' //缓存操作封装
import './permission'


// 字典标签组件
import DictTag from '@/components/DictTag'
import Contextmenu from "vue-contextmenujs"
Vue.component('DictTag', DictTag)
Vue.component('userMenu', userMenu)
Vue.prototype.Storage = Storage

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Contextmenu);
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')