<template>
  <div class="user-menu">
    <h3>&nbsp;&nbsp;{{ nickName }}&nbsp;&nbsp;工作菜单</h3>
    <div class="menu-list">
      <div
        @click="go(item)"
        class="menu-item"
        v-for="(item, i) in menuList"
        :key="i"
      >
        <div><i :class="item.icon"></i></div>
        <div>{{ item.title }}</div>
      </div>
      <!-- i标签占位 -->
      <i></i><i></i><i></i><i></i><i></i>
    </div>
    <el-image-viewer
      v-if="showPreview"
      :urlList="previewImages"
      :on-close="closeViewer"
    ></el-image-viewer>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { limit, getWeeklyPlan } from "@/api";
export default {
  name: "userMenu",
  components: {
    ElImageViewer,
  },
  props: {
    userId: {
      type: Number,
    },
    nickName: {
      type: String,
    },
  },
  data() {
    return {
      showPreview: false,
      previewImages: [],
      menuList: [
        {
          title: "文件夹",
          icon: "el-icon-folder-opened",
          type: 1, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "/files/" + this.$route.params.userId,
        },
        {
          title: "思维导图",
          icon: "el-icon-data-line",
          type: 1, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "/mind/" + this.$route.params.userId,
        },
        {
          title: "周计划",
          icon: "el-icon-s-claim",
          type: 4, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "/files/" + this.$route.params.userId,
        },
      ],
    };
  },
  methods: {
    closeViewer() {
      this.showPreview = false;
    },
    async go(item) {
      if (item.type === 2) {
        open(item.page);
      } else if (item.type === 3) {
        await limit({
          userId: this.$route.params.userId,
        }).then((res) => {
          this.previewImages = item.img;
          this.showPreview = true;
        });
      } else if (item.type === 4) {
        // console.log();
        getWeeklyPlan({ userId: this.$route.params.userId, type: 1 }).then(
          (res) => {
            if (res.code === 200) {
              // console.log(res);
              this.$router.push({
                name: "files",
                params: {
                  folderId: res.data.fileId,
                },
              });
              // this.$router.push(,);
            }
          }
        );
      } else {
        this.$router.push(item.page);
      }
    },
  },
  created() {
    if (this.nickName == "邓建波") {
      this.menuList.push(
        {
          title: "数据展示",
          icon: "el-icon-s-grid",
          type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "http://data.wantuyun.cn/",
        },
        {
          title: "部门总览",
          icon: "el-icon-monitor",
          type: 3, //1跳转到page，2新建标签页打开url，3弹出图片src
          img: ["https://oss.culturalcloud.net/furong/202211/21013531qlio.png"],
        },
        {
          title: "项目PM",
          icon: "el-icon-s-help",
          type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "https://kdocs.cn/l/caZwj5sw3bsN",
        }
      );
    }
    if (this.nickName == "叶晓宇") {
      this.menuList.push({
        title: "部门总览",
        icon: "el-icon-monitor",
        type: 3, //1跳转到page，2新建标签页打开url，3弹出图片src
        img: ["https://oss.culturalcloud.net/furong/202211/18171128dzid.png"],
      });
    }
    if (this.nickName == "高飞") {
      this.menuList.push(
        {
          title: "部门总览",
          icon: "el-icon-monitor",
          type: 3, //1跳转到page，2新建标签页打开url，3弹出图片src
          img: ["https://oss.culturalcloud.net/furong/202211/18171134by6i.png"],
        },
        {
          title: "项目PM",
          icon: "el-icon-s-help",
          type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "https://kdocs.cn/l/ccqfq2bSmkgU",
        }
      );
    }
    if (this.nickName == "金诚") {
      this.menuList.push(
        {
          title: "部门总览",
          icon: "el-icon-monitor",
          type: 3, //1跳转到page，2新建标签页打开url，3弹出图片src
          img: ["https://oss.culturalcloud.net/furong/202211/18180104j9q4.png"],
        },
        {
          title: "项目PM",
          icon: "el-icon-s-help",
          type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "https://kdocs.cn/l/ccqfq2bSmkgU",
        }
      );
    }
    if (this.nickName == "杜国辉") {
      this.menuList.push({
        title: "项目PM",
        icon: "el-icon-s-help",
        type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
        page: "https://kdocs.cn/l/ccqfq2bSmkgU",
      });
    }
    if (this.nickName == "胡浩楠") {
      this.menuList.push({
        title: "部门总览",
        icon: "el-icon-monitor",
        type: 3, //1跳转到page，2新建标签页打开url，3弹出图片src
        img: ["https://oss.culturalcloud.net/furong/202211/18175033amo5.png"],
      });
    }
    if (this.nickName == "杜欣毅") {
      this.menuList.push(
        {
          title: "部门总览",
          icon: "el-icon-monitor",
          type: 3, //1跳转到page，2新建标签页打开url，3弹出图片src
          img: ["https://oss.culturalcloud.net/furong/202211/19084416cifn.jpg"],
        },
        {
          title: "项目PM",
          icon: "el-icon-s-help",
          type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
          page: "https://kdocs.cn/l/chKarRAbEnI7",
        }
      );
    }
    if (this.nickName == "张友谊") {
      this.menuList.push({
        title: "项目PM",
        icon: "el-icon-s-help",
        type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
        page: "https://kdocs.cn/l/cl6ieOmQZVoe",
      });
    }
    if (this.nickName == "王来峰") {
      this.menuList.push({
        title: "项目PM",
        icon: "el-icon-s-help",
        type: 2, //1跳转到page，2新建标签页打开url，3弹出图片src
        page: "https://kdocs.cn/l/cl6ieOmQZVoe",
      });
    }
    if (this.nickName == "史颜峰") {
      this.menuList.push({
        title: "部门总览",
        icon: "el-icon-monitor",
        type: 3, //1跳转到page，2新建标签页打开url，3弹出图片src
        img: ["https://oss.culturalcloud.net/furong/202211/19084617km7w.jpg"],
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.user-menu {
  margin-top: 20px;
  width: 100%;
  // min-height: 100px;
  // background-color: pink;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.2);

  h3 {
    line-height: 40px;
    text-align: center;
    color: #8b8b8b;
  }

  .menu-list {
    width: 100%;
    padding: 10px 30px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    i {
      width: 100px;
    }

    .menu-item {
      width: 100px;
      height: 100px;
      margin-top: 20px;
      box-shadow: 0 2px 12px 0 rgb(0, 0, 0, 0.1);
      font-size: 18px;
      // font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #409eff;
      font-weight: bold;
      cursor: pointer;

      i {
        width: auto;
        font-size: 28px;
      }
    }
  }
}
</style>
